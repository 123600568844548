import { Box, TextField } from "@mui/material"
import { Search } from "@mui/icons-material"
import React from "react"

export const CfpSearch = ({ callSearch }) => {
  const [search, setSearch] = React.useState("")
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "446px",
          height: "50px",
          m: "15px 24px 0px 24px",
          alignItems: "center",
          top: "86px",
          background: "#F8F8F8",
          borderRadius: "32px",
        }}
      >
        <Search style={{ marginLeft: "20px", marginRight: "6px", color: "#818181" }} />
        <TextField
          variant="standard"
          placeholder="ユーザー名 / デバイス名 / メールアドレス / UUID"
          value={search}
          sx={{ width: "100%" }}
          InputProps={{
            disableUnderline: true,
            style: { border: "none", flexGrow: 1 },
          }}
          onChange={(e) => {
            setSearch(e.target.value)
            if (callSearch) callSearch(e.target.value)
          }}
        />
      </Box>
    </>
  )
}
