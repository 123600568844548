import Button from '@mui/material/Button'
import React, {useContext, useState, useRef, useEffect} from 'react'
import {
  Box
} from '@mui/material'
import { parse } from 'papaparse'

import FileUploadIcon from '@mui/icons-material/FileUpload'

import { GlobalStateContext } from '../../context/GlobalContextProvider'
import {gUtils} from "../utils/gutils";
import * as XLSX from 'xlsx';
import DescriptionIcon from '@mui/icons-material/Description'



// 卡片管理頁的CSV登錄功能

const transformExcelData = (excelData) => {
  if (!Array.isArray(excelData) || excelData.length === 0) {
    return [];
  }

  // 取得表头
  const headers = excelData[0];

  // 取得数据行，并转换为对象格式
  const csvData = excelData.slice(1).map(row => {
    let rowData = {};
    headers.forEach((header, index) => {
      rowData[header] = row[index];
    });
    return rowData;
  });

  return csvData;
};
const uploadCsv = async (e, set, close) => {
  e.preventDefault();
  const file = e.target.files[0];
  if (!file) return;



  const fileExtension = file.name.split('.').pop().toLowerCase();
  console.log("获取当前文件名", fileExtension);
  if (fileExtension === 'csv') {
    const text = await file.text();
    let data = parse(text, { header: true, skipEmptyLines: true }).data;

    // CSV データのバリデーションや加工をここで行う
    // 例：メールの形式チェック、必須項目のチェックなど

    // バリデーションをパスしたデータを親コンポーネントにセット

    console.log("获取到的CSV数据",data)
    set(data);

    // CSV アップロードモーダルを閉じる

    if (close){

      close(false);
    }

  } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      console.log("获取到的XLS数据",jsonData)
      let csvData = transformExcelData(jsonData);
      console.log("获取到的csvData数据",csvData)
      set(csvData);


      if (close){

        close(false);
      }
    };
    reader.readAsArrayBuffer(file);
  } else {
    console.error("不支持的文件类型");
  }

  e.target.value = null;

};

export const CSVbutton = ({ setData, isShowFileClick, setDataWarning, openModal, setOpenModal, setCsvLimitWarning, setCsvArray, handleOpen }) => {
  const { setCustomModalOpen, setModalTitle, setModalContent } = useContext(
    GlobalStateContext,
  )
  useEffect(() => {

    console.log("isShowFileClick2",isShowFileClick)
     if (isShowFileClick){
        setModalContent(CsvModalContent(setData))
        setModalTitle('CSV登録')
        setCustomModalOpen(true)
     }
  }, [isShowFileClick]);
  const fileRef = useRef(null)
  const handleSure = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }else {

      console.log("no")
    }
 //   fileRef.current && fileRef.current.click()
  }

  const CsvModalContent = (setData,call) => {
    return (
      <Box
        sx={{
          mt: '26px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          size="small"
          sx={{ mr: 1,color: "#28aeb1" }}
          onClick={() => {
            setCustomModalOpen(false)
          }}
        >
          キャンセル
        </Button>
        <Button
          disableElevation
          size="small"
          // disabled={selectedValue === ''}
          variant="outlined"
          sx={{
            color: "#28aeb1",
            border: "1px solid #28aeb1",
            "&:hover": {
              border: "1px solid #28aeb1",
            }
          }}
          onClick={(e) => {
            e.preventDefault()
            handleSure()
          }}
        >
          確定
        </Button>


        {/* 新增卡片上傳CSV */}
        <input
          ref={fileRef}
          type="file"
          accept=".csv, .xlsx, .xls"
          hidden
          onChange={(e) => {


            uploadCsv(e, setData, setCustomModalOpen)




          }}
        />
      </Box>
    )
  }


  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Button
        size="small"
        variant="outlined"
        component="label"
        startIcon={<FileUploadIcon />}
        sx={{ mr: 1 }}
        onClick={ (e) => {

          e.preventDefault()
          handleSure()


        }}
      >
        一括登録・更新
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          sx={{ color: "#28aeb1",mr: "4px" }}
          startIcon={<DescriptionIcon />}
          onClick={() => {

            gUtils.csvUtils.downloadExcelTemp()

          }}
        >
          Windows向け
        </Button>
        <Button
          size="small"
          sx={{ color: "#28aeb1" }}
          startIcon={<DescriptionIcon />}
          onClick={() => {

            gUtils.csvUtils.downloadCsv()

          }}
        >
          Mac向け
        </Button>
      </Box>
      <input
          ref={fileRef}
          type="file"
          accept=".csv, .xlsx, .xls"
          hidden
          onChange={(e) => {


            uploadCsv(e, setData)




          }}
      />
    </Box>
  )
}
//   gUtils.csvUtils.downloadLists(data)
export default function CSVHandler({ setData, isUserData=true,isUploadCsv=true,isUploadCsvCall=undefined,isShowFileClick=false}) {

  const [ setOpenModal] = useState(false)
  const handleOpen = () => setOpenModal(true)

  return (
    <>
      <CSVbutton
        setData={setData}
        isUploadCsv={isUploadCsv}
        isUploadCsvCall={isUploadCsvCall}
        isShowFileClick={isShowFileClick}
        isUserData={isUserData}
        setOpenModal={setOpenModal}

        handleOpen={handleOpen}
      />
    </>
  )
}
