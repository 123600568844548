import React, {  useState} from "react"

import {

    Box,

    IconButton,
    Typography,
    Card,
    CardHeader,
    CardContent,

} from "@mui/material"
import Hider from "../../utils/Hider"

import AddCircleIcon from "@mui/icons-material/AddCircle"

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

import CSVHandler from "../csvHandler"
import {Cfpupload} from "../cfpupload"

export const CfpheaderCards = ({
                                   setClose,
                                   data,
                                   mdatas,
                                   addCard,
                                   goSet,
                                   buttonTitle,

                                   isBindMm = true,
                                   csvData,
                                   csvDownData,
                                   isShowCsv = true,
                                   tagTitle,
                                   id="cardID"
                               }) => {


    const [readCardContent, setReadCardContent] = useState(false)


    const readCardClick = () => {
        let nIschoose = !readCardContent

        setReadCardContent(nIschoose)
        if (goSet) {
            goSet(nIschoose)
        }
    }



    return (
        <Card>
            <CardHeader
                title={
                    <Box
                        sx={{
                            display: "flex",
                            mt: "20px",
                            mb: "1px",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h2" sx={{ml: "9px"}}>
                            {data.title}
                        </Typography>

                        <Box sx={{display: "flex"}}>
                            {isShowCsv && (
                                <CSVHandler setData={csvData} downData={csvDownData}/>
                            )}

                            <IconButton
                                onClick={() => {
                                    setClose()
                                }}
                                sx={{"&:hover": {color: "primary.main"}}}
                            >
                                <KeyboardArrowUpIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                }
            />
            <CardContent>

                <Box sx={{mt: "1px"}}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <Typography variant="h3" sx={{ml: "9px"}}>
                            {data.h2}
                        </Typography>

                        <IconButton
                            onClick={readCardClick}
                            size="small"
                            variant="outlined"
                            sx={{
                                color: "#28AEB1",
                            }}
                        >
                            {!readCardContent ? (
                                <AddCircleIcon style={{color: "#28AEB1"}}/>
                            ) : (
                                <RemoveCircleIcon style={{color: "#28AEB1"}}/>
                            )}
                        </IconButton>
                    </Box>
                    <Hider show={!readCardContent} sx={{marginTop: "20px"}}>
                        {/* 按取得按鈕前的顯示 */}
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{ml: "9px", mt: "10px", color: "#9B9B9B"}}
                            >
                                {data.b2t}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Cfpupload
                                id={id}
                                mdatas={mdatas}
                                addCard={addCard}
                                buttonTitle={buttonTitle}
                                isBindMm={isBindMm}
                                tagTitle={tagTitle}
                            />
                        </Box>
                    </Hider>
                </Box>
            </CardContent>
        </Card>
    )
}
