import React, { useState, useContext } from "react"
import { Box, Typography, TextField, Button } from "@mui/material"
import { CfpBindMM } from "./cfpMm"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

export const Cfphandleinput = ({ addData, buttonTitle, isBindMm = true }) => {
  const { ts } = useContext(GlobalStateContext)
  const [item, setItem] = useState({ id: "", n: "", mm: "", mmid: "" })
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "600",
          pl: "48px"
        }}
      >
        暗証番号を手入力
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
            ml:'50px',
          gap: 4,
          mt: "20px",
        }}
      >
        <TextField
          label="暗証番号"
          required
          size="small"
          variant="outlined"
          value={item.id}
          type='number'
          onChange={(e) => setItem({ ...item, id: e.target.value })}
          sx={{
            width: "300px",
            // 矢印を非表示
            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            '& input[type=number]': {
              '-moz-appearance': 'textfield',
            },
          }}
        />

        <TextField
          label="暗証番号名"
          required
          size="small"
          variant="outlined"
          value={item.n}
          onChange={(e) => setItem({ ...item, n: e.target.value })}
        />
        {isBindMm && (
          <Box sx={{ width: "20%" }}>
            <CfpBindMM
              callBindMm={(value) => {
                setItem({
                  ...item,
                  mm: value.employeeName,
                  mmid: value.subUUID,
                })
              }}
            />
          </Box>
        )}

        <Button
          disabled={!item.n && !item.mm}
          disableElevation
          variant="contained"
          color="primary"
          sx={{
            color: "white",
            backgroundColor: !item.n && !item.mm ? "#FFFFFF" : "#28AEB1",
            "&:hover": {
              backgroundColor: !item.n && !item.mm ? "#FFFFFF" : "#28AEB1",
            },
          }}
          onClick={() => {
            if (!item.id) {
              ts({ open: true, msg: "暗証番号を入力して下さい" })

              return
            }

            if (addData) addData(item)
            setItem({ id: "", n: "", mm: "", mmid: "" })
          }}
        >
          {buttonTitle || "登録"}
        </Button>
      </Box>
    </Box>

  )
}
