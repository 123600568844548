import Layout from "../../layouts"
import React, {useState, useContext, useEffect, useRef} from "react"

import {CfpSearch} from "../../components/cfp/cfpSearch"
import {Box,} from "@mui/material"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {CfpheaderCards} from "../../components/cfp/muidb/cfpheadercards"

import Cfptb from "../../components/cfp/cfptb"
import {cfpColumns} from "../../components/utils/cfpcolumns"
import Hider from "../../components/utils/Hider"
import {Cfphandleinput} from "../../components/cfp/cfphandleinput"
import {gUtils} from "../../components/utils/gutils"
import {navigate} from "gatsby";
import {gConfig} from "../../chatai/utils/config";
import {CfpDropdown, DownloadList} from "../../components/cfp/cfpDropdown";

export default function VCards({location}) {
    const {state = {}} = location
    const [openHead, setopenHead] = useState(false)
    const {gf, gCfpfp, gCfp, gIotCfp} = useContext(GlobalStateContext)
    const [tbData, settbData] = useState([])
    const {title} = state || '';
    const ref = useRef(null)
    const [openDropDown, setOpenDropDown] = useState(false)
    const [listDown, setListDown] = useState([])
    const [dropdownPosition, setDropdownPosition] = useState({left: 0, top: 0})

    const filterList = (list, deviceID) => {
        // 创建一个映射，以便快速查找每个 passwordID 对应的 deviceID 列表
        const passwordDeviceMap = list.reduce((acc, item) => {
            if (!acc[item.passwordID]) {
                acc[item.passwordID] = [];
            }
            acc[item.passwordID].push(item.deviceID);
            return acc;
        }, {});
        return list
            .filter(item => item.deviceID === deviceID)
            .map(item => ({
                ...item,
                uuids: passwordDeviceMap[item.passwordID] || []
            }));
    };

    useEffect(() => {
        if (!title) {
            navigate("/");
        } else {
            if (!openHead) {
                gIotCfp.sendCmd(state.uuid, gConfig.cmdCode.passwordSetFinish)
            }
            settbData(filterList(gCfpfp.devicePasswords, state.uuid))
            gCfpfp.setTemDevicePasswords([])
            return () => {
                gIotCfp.sendCmd(state.uuid, gConfig.cmdCode.passwordSetFinish)
            }
        }

    }, [openHead])
    useEffect(() => {
        gIotCfp.subIotDevices()
        return () => {
            gIotCfp.cancelSubscriptions();

        }
    }, []);


    useEffect(() => {
        if (state && state.uuid) {
            settbData(filterList(gCfpfp.devicePasswords, state.uuid))
        }


    }, [gCfpfp.devicePasswords])

    function formatNumber(inputNumber) {
        const numStr = inputNumber.toString();
        return Array.from(numStr, digit => digit.padStart(2, '0')).join('');
    }

    return (
        <Layout location={location}>
            <Box sx={gf.styles.v}>
                <CfpSearch
                    callSearch={(e) => {
                        if (e) {
                            settbData(
                                gUtils.findSearchList(
                                    e,
                                    filterList(gCfpfp.devicePasswords, state.uuid),

                                    gCfp.cfpDevices
                                )
                            )
                        } else {
                            settbData(filterList(gCfpfp.devicePasswords, state.uuid))
                        }
                    }}
                />
                <Hider show={openHead}>
                    <Box>
                        <CfpheaderCards
                            isBindMm={false}
                            id={'passwordID'}
                            csvData={(data) => {
                                let results = []
                                console.log("当前数据pw", data)
                                data.forEach((item) => {
                                    if (item.暗証番号 || item.id || item.ID || item.passwordID) {
                                        results.push({
                                            passwordID: formatNumber(item.暗証番号 || item.id || item.ID || item.passwordID), // 首先尝试使用item.id，如果不存在，则使用item.ID
                                            name: item.暗証番号名 || item.name || "", // 如果item.n存在，则使用item.n，否则默认为空字符串
                                        });
                                    }
                                })
                                if (results.length > 0) {
                                    gIotCfp.sendCmds(state.uuid, gConfig.cmdCode.passwordPut, results.map(item => {
                                        return gUtils.toCardInfo(item.passwordID, 0, item.name)
                                    }))
                                }
                            }}
                            setClose={() => {
                                setopenHead(false)
                            }}
                            data={gf.fonts.fontCfpPw}
                            goSet={(isChoose) => {
                                gIotCfp.sendCmd(state.uuid, isChoose ? gConfig.cmdCode.passwordSet : gConfig.cmdCode.passwordSetFinish)
                            }}
                            mdatas={gCfpfp.temDevicePasswords}
                            addCard={(d) => {
                                gCfpfp.updateFp("p", state.uuid, d)


                            }}
                            tagTitle={"暗証番号名"}
                        />

                    </Box>
                </Hider>
                <Cfptb
                    callAdd={() => {
                        setopenHead(true)
                    }}
                    isAdd={!openHead}
                    data={tbData}
                    path={"/cfp/passworddetails"}
                    isBind={false}
                    isBack={true}
                    text={`${title}${gf.fonts.fontCfpPw.listTitle}`}
                    columns={cfpColumns.passwordColumns({
                        click: (isGroup, value, e) => {
                            const rect = e.currentTarget.getBoundingClientRect()
                            setDropdownPosition({left: rect.left, top: rect.top})
                            setOpenDropDown(true)
                            if (!isGroup) {
                                setListDown(gUtils.uuidsToNames(value, gCfp.cfpDevices))
                            } else {
                                setListDown(value)
                            }
                        }, ref: ref
                    })}
                    callDelData={(items) => {

                        gIotCfp.sendCmds(state.uuid, gConfig.cmdCode.passwordDelete, items.map(item => {
                            return gUtils.toCardInfo(item.passwordID, 0, item.name)
                        }))

                    }}
                />

                <Cfphandleinput
                    addData={(item) => {
                        gIotCfp.sendCmd(state.uuid, gConfig.cmdCode.passwordPut, gUtils.toCardInfo(formatNumber(item.id), 0, item.n))
                    }}
                    isBindMm={false}
                />
                <CfpDropdown
                    open={openDropDown}
                    closeOpen={() => {
                        setOpenDropDown(false)
                    }}
                    position={dropdownPosition}
                    viewRef={ref}
                >
                    <DownloadList list={listDown}/>
                </CfpDropdown>
            </Box>
        </Layout>
    )
}
