import * as React from "react";
import {  List, ListItem, ListItemText} from "@mui/material";
import {useEffect, useRef, useState} from "react";


export const CfpDropdown=({open, closeOpen ,children, position})=> {
    const ref = useRef(null);
    const [finalPosition, setFinalPosition] = useState({ top: 0, left: 0 });

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            closeOpen();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });


    useEffect(() => {
        console.log("position",position)
        setFinalPosition({
            top: position.top + 26,
            left: position.left
        });
    }, [position]);
    return (open && (
        <div ref={ref} style={{position: 'fixed', top: `${finalPosition.top}px`, left: `${finalPosition.left}px`}}>
            {children}
        </div>
    ));
}


export const DownloadList=({list})=>{



    return <>

        <List sx={{
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', // 阴影效果
            padding: '5px 3px',
            zIndex:'1000',
            background:'white'


        }}>
            {list.map((item, index) => (
                <ListItem  sx={{
                    display: 'flex',  // 添加display flex
                    flexDirection: 'row',  // 方向为行

                    flexWrap: 'nowrap'  // 如果一行装不下，就换行
                }}key={index}>
                    <ListItemText primary={item} />
                </ListItem>
            ))}
        </List>
    </>
}
