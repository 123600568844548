import React, { useEffect, useState } from "react"
import { Box, Button, TextField, Typography } from "@mui/material"
import { CfpBindMM } from "./cfpMm"
import {gUtils} from "../utils/gutils";

export const Cfpupload = ({
  mdatas = [],
  isBindMm = true,
  addCard,
  buttonTitle = "修正",
  tagTitle = "カード名",
  id='cardID'
}) => {
  const [cards, setCards] = useState([])

  useEffect(() => {

    setCards(mdatas)
  }, [mdatas])

  return (
    <>
      {cards.map((item,index) => (
        <React.Fragment key={item[id]}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mt: "20px",
              ml: "9px",
              flexDirection: "row",
            }}
          >
            <Box sx={{ display: "flex", width: "30%" }}>
              <Typography variant="h4" sx={{ marginRight: "20px" }}>
                {(index+1)}
              </Typography>

              <Typography variant="h4">{id==='cardID'?item[id]:gUtils.binaryToDecimal(item[id])}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "top",
                gap: 2,
                width: "40%",
              }}
            >
              <Box sx={{ width: "40%" }}>
                <TextField
                  label={tagTitle}
                  required
                  size="small"
                  variant="outlined"
                  value={item.name}
                  onChange={(e) => {
                    // setCardName(e.target.value)
                    setCards(
                      cards.map((card) =>
                        card[id] === item[id]
                          ? { ...card, name: e.target.value }
                          : card
                      )
                    )
                  }}
                  sx={{ width: "100%" }}
                />
              </Box>

              {isBindMm && (
                <Box sx={{ width: "40%" }}>
                  <CfpBindMM
                    callBindMm={(value) => {
                      console.log("callBindMm", value)
                      setCards((prevCards) =>
                        prevCards.map((card) =>
                          card[id] === item[id]
                            ? {
                                ...card,
                                mm: value.employeeName,
                                memberID: value.subUUID,
                              }
                            : card
                        )
                      )
                    }}
                  />
                </Box>
              )}
            </Box>
            <Button
              disabled={!item.name && !item.memberID} // 当 item.n 和 item.mm 都为空时，按钮被禁用
              disableElevation
              variant="contained"
              color="primary"
              sx={{
                color: "white",
                backgroundColor: !item.name && !item.memberID ? "#FFFFFF" : "#28AEB1",
                "&:hover": {
                  backgroundColor: !item.name && !item.memberID ? "#FFFFFF" : "#28AEB1",
                },
              }}
              onClick={() => {
                if (addCard) addCard(item)
                setCards((prevCards) => {
                  return prevCards.filter(card => card[id] !== item[id]);
                });



              }}
            >
              {buttonTitle}
            </Button>
          </Box>
        </React.Fragment>
      ))}
    </>
  )
}
